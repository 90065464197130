<!-- @format -->

<template>
  <div>
    <div v-if="loader" class="bg-white">
      <div
        role="status"
        class="space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center px-4 py-4 border bg-white"
      >
        <div
          class="flex items-center justify-center bg-gray-300 rounded dark:bg-gray-500"
        >
          <svg
            class="w-10 h-10 text-gray-200 dark:text-gray-600"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 18"
          >
            <path
              d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
            />
          </svg>
        </div>
        <div class="flex items-center justify-between w-full">
          <div>
            <div
              class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-500 w-24 mb-2.5"
            ></div>

            <div
              class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-500"
            ></div>
          </div>
          <div class="pl-52 pb-3">
            <div
              class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-500 w-80 my-2"
            ></div>
          </div>
        </div>
      </div>
      <div class="mt-16">
        <TableSkeletonLoader :loader="loader" :cols="3" :rows="4" />
      </div>
    </div>
    <div v-else class="bg-white rounded-md">
      <div class="py-4 px-6 flex w-full gap-2">
        <div class="material-icons text-4xl w-12 text-blue-500">
          <img
            class="w-full"
            src="../../../../assets/icons/compliance_File.svg"
          />
        </div>
        <div class="w-full">
          <div class="text-lg font-medium line-clamp capitalize">
            {{ singleComplianceItem?.name }}
          </div>
          <div class="flex pt-2 gap-3 align-center">
            <div class="flex gap-2 font-light text-sm items-center capitalize">
              <span class="material-icons w-5 text-teal"
                >format_list_bulleted_icon</span
              >
              {{ singleComplianceItem?.sub_type }}
            </div>
            <div class="flex font-light text-sm items-center">
              <img
                class="w-6 pr-1"
                src="../../../../assets/icons/calendar_clock.svg"
              />
              {{
                singleComplianceItem?.due_date
                  ? moment(singleComplianceItem?.due_date).format(
                      "MMM DD, YYYY"
                    )
                  : moment(singleComplianceItem?.end_date).format(
                      "MMM DD, YYYY"
                    )
              }}
            </div>
            <div class="flex gap-2 font-light text-sm items-center">
              <span class="material-icons w-5 text-teal">update_icon</span>
              {{
                singleComplianceItem?.is_recurring
                  ? occurenceFormat(singleComplianceItem?.occurrence)
                  : "Once"
              }}
            </div>
            <div class="pt-1 text-sm">
              <span
                class="px-2 py-1 rounded-md bg-blue bg-opacity-10 text-blue-500"
              >
                {{ singleComplianceItem?.submitted_practices_count }}/{{
                  singleComplianceItem?.total_practices_count
                }}
              </span>
            </div>
            <div
              class="flex items-center text-teal font-semibold opacity-70 cursor-pointer"
              @click="
                router.push({
                  name: 'hq-monitoringOfPracticeFunctionsSingleComplianceDetails',
                  params: { complianceId: complianceId, isArchived: 0 },
                })
              "
            >
              Open
              <div class="material-icons text-lg ml-1">launch</div>
            </div>
          </div>
        </div>
        <div class="w-1/4">
          <v-select
            class="pt-2"
            label="status"
            :searchable="false"
            @update:modelValue="filteredData"
            :options="
              singleComplianceItem?.compliance_calendar_type?.name
                ?.toLowerCase()
                ?.includes('policies')
                ? statusOptionsPolicies
                : statusOptions
            "
            placeholder="Filter Practices By"
          />
        </div>
      </div>
      <div class="relative overflow-x-auto bg-white">
        <table class="w-full text-sm text-left overflow-hidden">
          <thead class="text-sm text-white bg-teal border-b">
            <tr>
              <th
                scope="col"
                class="px-6 py-4 tracking-wider font-medium"
                v-for="header in tableHeadings"
                :key="header"
              >
                {{ header }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="border-b w-full"
              v-for="(practice, idx) in practicesForCompliance?.practices?.data"
              :key="practice.id"
            >
              <th
                class="px-6 py-4 font-medium text-gray-700 capitalize truncate w-2/3"
                :class="
                  singleComplianceItem?.compliance_calendar_type?.name?.includes(
                    'Policies'
                  )
                    ? 'cursor-pointer'
                    : ''
                "
                @click="
                  singleComplianceItem?.compliance_calendar_type?.name?.includes(
                    'Policies'
                  )
                    ? navigate(practice.id)
                    : ''
                "
              >
                {{ practice.practice_name }}
              </th>
              <td class="px-6 py-4">
                <div
                  class="status align-middle flex justify-center items-center w-max rounded-lg h-6 p-2"
                  :class="
                    practice?.status === 4 || practice?.status === 1
                      ? 'bg-blue-50 text-blue'
                      : 'bg-yellow-50 text-yellow'
                  "
                >
                  <span
                    class="capitalize"
                    :class="
                      practice?.status === 1 || practice?.status === 4
                        ? 'text-blue'
                        : 'text-yellow-400'
                    "
                  >
                    {{
                      singleComplianceItem?.compliance_calendar_type?.name
                        ?.toLowerCase()
                        ?.includes("policies")
                        ? practice?.status === 4
                          ? "Reviewed"
                          : "Un Reviewed"
                        : practice?.status === 0
                        ? "Pending"
                        : "Completed"
                    }}
                  </span>
                </div>
              </td>
              <td class="material-icons text-4xl w-20 py-4 flex items-center">
                <img
                  class="w-full"
                  :class="
                    findPractices(
                      idx,
                      practice.id,
                      practicesForCompliance.files
                    )
                      ? ''
                      : 'gray-scale'
                  "
                  src="../../../../assets/icons/compliance_File.svg"
                />
                <div
                  class="px-2"
                  :class="
                    file[idx]
                      ? ' text-sm font-medium cursor-pointer'
                      : 'text-sm text-gray-400 font-bold'
                  "
                  @click="downloadFile(file[idx]?.file, file[idx]?.file_name)"
                >
                  {{
                    file[idx]
                      ? file[idx]?.file_name?.length > 15
                        ? file[idx]?.file_name?.slice(0, 8) +
                          "..." +
                          file[idx]?.file_name?.slice(
                            file[idx]?.file_name?.length - 8
                          )
                        : file[idx]?.file_name
                      : "Unavailable"
                  }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <Pagination
          :currentPage="practicesForCompliance?.practices?.current_page"
          :totalPages="practicesForCompliance?.practices?.last_page"
          @page-changed="atPageChange"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRouter, useRoute } from "vue-router";
import { onMounted, computed, ref } from "vue";
import TableSkeletonLoader from "@/components/newUi/atoms/TableSkeletonLoader.vue";

import Pagination from "@/components/newUi/atoms/Pagination.vue";
import { useStore } from "vuex";
import moment from "moment";
import useDownloadFile from "@/composables/useDownloadFile";

const { downloadFile } = useDownloadFile();

const router = useRouter();
const route = useRoute();
const store = useStore();
const loader = ref(false);
const complianceId = route.params.complianceId;
const file = ref([]);
const tableHeadings = ["Practices List", "Status", "File"];
const statusOptions = ref([
  {
    id: 0,
    status: "Pending",
  },
  {
    id: 1,
    status: "Completed",
  },
]);

const statusOptionsPolicies = ref([
  {
    id: 4,
    status: "Reviewed",
  },
  {
    id: 5,
    status: "Un Reviewed",
  },
]);

const selectedStatus = ref(null);

const occurenceFormat = (occurrence) => {
  switch (occurrence) {
    case 1:
      return "One Month";
    case 2:
      return "Two Months";
    case 3:
      return "Three Months";
    case 4:
      return "Four Months";
    case 5:
      return "Five Months";
    case 6:
      return "Six Months";
    case 7:
      return "Seven Months";
    case 8:
      return "Eight Months";
    case 9:
      return "Nine Months";
    case 10:
      return "Ten Months";
    case 11:
      return "Eleven Months";
    case 12:
      return "Twelve Months";
  }
};

const findPractices = (id, practiceid, files) => {
  if (files) {
    file.value[id] = files.find((practice) => {
      return practice.practice === practiceid;
    });
    if (file.value[id]) {
      return true;
    } else {
      return false;
    }
  } else {
    file.value[id] = null;
  }
};

const filteredData = (status) => {
  if (status) {
    selectedStatus.value = status.id;
    fetchPaginatedComplianceByPractices(1, selectedStatus.value);
  } else {
    selectedStatus.value = null;
    fetchPaginatedComplianceByPractices(1);
  }
};

const atPageChange = (page) => {
  if (page > practicesForCompliance.value?.practices?.last_page) {
    return;
  } else {
    fetchPaginatedComplianceByPractices(page, selectedStatus.value);
  }
};

const navigate = (practiceId) => {
  router.push({
    name: "hq-monitoringOfPracticeFunctionsComplianceResponses-BySinglePractice",
    params: { complianceId: complianceId, practiceId: practiceId },
  });
};

const singleComplianceItem = computed(() => {
  return store.getters["hqComplianceCalender/getSingleComplianceItem"];
});

const practicesForCompliance = computed(() => {
  return store.getters["hqComplianceCalender/getPracticesForCompliance"];
});

const fetchPaginatedComplianceByPractices = async (page, status) => {
  let payload;

  if (page && status !== null) {
    payload = {
      complianceId: complianceId,
      page: page,
      status: status,
    };
  } else if (page && status === null) {
    payload = {
      complianceId: complianceId,
      page: page,
    };
  } else if (status !== null && !page) {
    payload = {
      complianceId: complianceId,
      status: status,
    };
  }
  await store.dispatch(
    "hqComplianceCalender/fetchPaginatedComplianceByPractices",
    payload
  );
};

const fetchCompliance = async () => {
  loader.value = true;
  await store
    .dispatch("hqComplianceCalender/fetchSingleComplianceItem", {
      complianceId: complianceId,
    })
    .then(() => {
      loader.value = false;
    });
};

onMounted(fetchCompliance(), fetchPaginatedComplianceByPractices(1));
</script>

<style scoped>
::v-deep .vs__dropdown-toggle {
  padding: 6px;
  background-color: white;
  cursor: pointer !important;
}
::v-deep ul li:not(:hover) {
  background-color: white !important;
  color: black;
}
::v-deep ul li:hover {
  background-color: teal !important;
}
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.gray-scale {
  filter: grayscale(1);
}
</style>
